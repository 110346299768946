import React from 'react';
import logo from './logo.svg';
import {useEffect} from "react";
import { Home } from './pages/home';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';


function App() {


  return (
    <div className="App">
      <BrowserRouter>
              <Routes>
                  <Route path="/" element={<Home />} />
                  <Route
                      path="*"
                      element={<Navigate to="/" replace />}
                  />
              </Routes>
          </BrowserRouter>
    </div>
  );
}

export default App;
