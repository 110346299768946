import { InjectedConnector } from '@web3-react/injected-connector';




export const injected = new InjectedConnector({
    supportedChainIds: [1, 2, 3, 4, 5, 56, 43113, 97, 31337, 43114, 250, 137, 25, 42161, 1088, 588, 42161, 10, 100, 1284, 421613, 420]
});


