import React, { useState } from "react";
import { useWeb3React } from "@web3-react/core";
import metamaskImg from "../img/metamask.png";
import {CHAIN_ID} from "../constants";
import trimAddress from "../utils/address";
import { useEagerConnect, useInactiveListener } from "../utils/hooks";
import {injected} from "../utils/connector";
import {Provider} from "../utils/provider";



const ConnectButton = (props: any) => {
    const { activate, active, account, library, chainId, deactivate } = useWeb3React<Provider>();


    const [activating, setActivating] = useState(false);
    const [deactivating, setDeactivating] = useState(false);

    const handleActivate = () => {
        const _activate = async (activate:any) => {
            setActivating(true);
            await activate(injected);
            setActivating(false);
        }

        _activate(activate);
    }

    const handleDeactivate = () => {
        //TODO Debug this to disconnect
        const _deactivate = async (deactivate:any) => {
            setDeactivating(true);
            try {
                deactivate();
            } catch (error) {
                console.log(error);
            }

            setDeactivating(false);
        }

        _deactivate(deactivate);
    }

    const eagerConnectionSuccessful = useEagerConnect();
    useInactiveListener(!eagerConnectionSuccessful);

    const switchNetwork = () => {
        if (library) {
            library.provider.request({
                method: "wallet_switchEthereumChain",
                params: [
                    {
                        chainId: "0x1",
                    },
                ],
            });
        }
    }

    if (chainId && chainId != CHAIN_ID) {
        return <button className="btn-fullwhite animated fadeIn" onClick={switchNetwork} >
        <img alt="btn" style={{verticalAlign:'middle'}} src={metamaskImg} width={20} />
        Switch Network
        </button>
    }

    return account? <button className="btn-fullwhite animated fadeIn" onClick={handleDeactivate}>{trimAddress(account)}</button> : <button className="btn-fullwhite animated fadeIn" onClick={handleActivate} ><img style={{verticalAlign:'middle'}} src={metamaskImg} width={20} /> 1. Connect your wallet</button>

}

export default ConnectButton;