import ConnectButton from "../components/connect-button";
import logoOuibild from "../img/logooubild.png";
import { useWeb3React } from "@web3-react/core";
import {toast, ToastContainer} from "react-toastify";
import { Provider } from "../utils/provider";
import trimAddress from "../utils/address";

export const Home = () => {
  const { account } = useWeb3React<Provider>();

  const verifyAddress = () => {
    if(account){
      let twitterlink = "https://twitter.com/intent/tweet?text=";
        let ticker = `${trimAddress(account)}%0A%0A`;
        let prefix_message = "Become a landlord with @ouibild.%0A%0A";
        let suffix_message = `Early adopter will be rewarded... %0A%0ACome Bild your NFT real estate empire with us.%0A%0AVerify yourself on our Dapp and claim your Whitelist spot!%0A⏬⏬%0Ahttps://app.ouibild.io/%0A%0A`;
        window.open(twitterlink.concat(prefix_message, suffix_message, ticker));
    } else {
      toast.error("Connect your wallet first !")
    }
  }
  return (


    <>
      <section className="hero is-fullheight coming-soon">
        {/* Hero head: will stick at the top */}
        <div className="hero-head has-text-centered">
          <img className="logo animated fadeIn " src={logoOuibild} alt="Logo" />
        </div>
        {/* Hero content: will be in the middle */}
        <div className="hero-body">
          <div className="container has-text-centered animated fadeIn delay-05">
            <p className="subtitle is-hidden-mobile is-3 white">
              Real estate revolution is
            </p>
            <p className="subtitle is-hidden-tablet is-5 white">
              Real estate revolution is
            </p>
            <h1 style={{}} className="title is-1 monster-title white ">Coming Soon</h1>
            <p className="subtitle is-6 white ">
              Make a contribution by checking your wallet, a surprise is waiting for you !
            </p>
            <ConnectButton/>
            <button onClick={verifyAddress} className="btn-outline-white animated fadeIn ">2. Verify yourself</button>
          </div>
        </div>
      </section>
      <ToastContainer
              position="top-right"
              autoClose={3000}
                  hideProgressBar={true}
                  newestOnTop={false}
                  closeOnClick
                  theme='dark'
              rtl={false} />
    </>
  )
}